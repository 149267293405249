import { render, staticRenderFns } from "./merchantTransactions.html?vue&type=template&id=5f86812a&scoped=true&"
import script from "./merchantTransactions.js?vue&type=script&lang=js&"
export * from "./merchantTransactions.js?vue&type=script&lang=js&"
import style0 from "./merchantTransactions.scss?vue&type=style&index=0&id=5f86812a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f86812a",
  null
  
)

export default component.exports