import transactionsService from "../../../../services/transactionsService";
import $statusDictionary from "./../../../../mixins/statusDictionary";
import $formatNumber from "./../../../../mixins/formatNumber";
import $renderDate from "./../../../../mixins/renderDate";

export default {
    name: 'merchant-transactions',
    components: {},
    props: [],
    mixins: [$statusDictionary, $formatNumber, $renderDate],
    data() {
        return {
            transactions: "",
            statuses: "",
            refIdSearch: null,
            orderIdSearch: null,
            statusText: "همه",
            status: null,
            typeText: "همه",
            types: "",
            type: null,
            currentPage: 1,
            tableHeaders: [
                "صاحب کسب و کار",
                "نام کسب و کار ",
                "مقدار به ریال",
                "ارز پرداختی کاربر",
                "ارز دریافتی فروشگاه",
                "کارمزد لحظه‌ای",
                "کارمزد کاربر",
                "کارمزد تخلیه",
                "نسبت ریال به ارز مرجع",
                "نسبت ارز دیجیتال به ارز مرجع",
                "تاریخ تراکنش",
                "نوع تراکنش",
                "وضعیت",
            ],
        }
    },
    computed: {

    },
    mounted() {
        this.getStatusFunc();
        this.getAdminTransactions();
        this.getTypesFunc();
    },
    methods: {
        getAdminTransactions() {
            transactionsService
                .getAdminTransactions(
                    0,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.$route.query.merchantid
                )
                .then((response) => {
                    this.transactions = response.data;
                });
        },
        changeStatus(status, text) {
            this.status = status;
            this.statusText = text;
        },
        changeType(type, text) {
            this.type = type;
            this.typeText = text;
        },
        getStatusFunc() {
            transactionsService.getstatus().then((response) => {
                this.statuses = response.data;
            });
        },
        getTypesFunc() {
            transactionsService.getMerchantOrdersTypes().then((response) => {
                this.types = response.data;
            });
        },
        submitFilterTransactions() {
            if (this.refIdSearch === "") {
                this.refIdSearch = null;
            }
            if (this.orderIdSearch === "") {
                this.orderIdSearch = null;
            }
            transactionsService
                .getAdminTransactions(
                    0,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.$route.query.merchantid,
                    this.status,
                    this.type,
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.currentPage = 1;
                    this.transactions = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getPaginationData(id) {
            transactionsService
                .getAdminTransactions(
                    id - 1,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.$route.query.merchantid,
                    this.status,
                    this.type
                )
                .then((response) => {
                    this.transactions = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
    },
}


